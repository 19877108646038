import React, { useState } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import { humanReadableFormattedDateString } from "../../../utils/UtilsService";
import Datatable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import QuickSearchToolbar from "../QuickSearchToolbar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton  color='success' title="ABC" label={'ds'} startIcon={<Avatar />}  /> */}
      {/* <GridToolbarExport color='success' csvOptions={
        {
          fileName: 'customerList',
          utf8WithBom: true,
        }} /> */}
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}
export default function Media({ reportData }) {
  console.log(reportData, "aa");

  const rows1 = [];
  if (reportData && reportData.length > 0) {
    reportData.forEach((item) => {
      rows1.push({
        id: item.mediaObject._id,
        media: item.mediaObject._id,

        duration: item.duration,
        loop_count: item.loop,
      });
    });
  }
  const columns = [
    {
      name: "Media",
      selector: (row) => row?.mediaObject?._id,
    },
    {
      name: "Loop Count",
      selector: (row) => row?.loop,
    },
    {
      name: "Duration",
      selector: (row) => row?.duration,
    },
  ];

  const columns1 = [
    {
      field: "media",
      headerName: "Media",
      flex: 1,
    },
    {
      field: "loop_count",
      headerName: "Loop Count",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
    },
  ];

  return (
    <>
      <div className="h-100">
        <DataGrid
          getRowHeight={() => "auto"}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
          rows={rows1}
          columns={columns1}
          pageSize={10}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          //loading={loading}
          pagination
          zIndex={-1}
        />
      </div>
      {/* <Table
        responsive
        className="custom-table screen-table"
        style={{ height: "100%" }}
        id="external-events"
      >
        <thead>
          <tr>
            <th>Media</th>
            <th>Loop Count</th>
            <th>Duration</th>
          </tr>
        </thead>

        <tbody>
          {reportData.length > 0 &&
            reportData.map((data) => {
              return (
                <tr key={data?._id}>
                  <td>{data?.media}</td>
                  <td>{data.loop} </td>
                  <td>{data.duration} </td>
                </tr>
              );
            })}
        </tbody>
        {reportData?.length === 0 && <h3 className="mt-5">No Report Found</h3>}
      </Table> */}
      {/* <Datatable columns={columns} data={reportData} pagination /> */}
    </>
  );
}
