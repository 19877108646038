import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Datatable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import QuickSearchToolbar from "../QuickSearchToolbar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton  color='success' title="ABC" label={'ds'} startIcon={<Avatar />}  /> */}
      {/* <GridToolbarExport color='success' csvOptions={
        {
          fileName: 'customerList',
          utf8WithBom: true,
        }} /> */}
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}
export default function Uptime({ reportData }) {
  console.log(reportData, "uptimeeee");
  const rows1 = [];
  if (reportData && reportData.length > 0) {
    reportData.forEach((item) => {
      console.log(item, "ppp");
      rows1.push({
        id: item._id,
        screen: item.name,

        total_uptime: item,
        total_avg_uptime: item,
      });
    });
  }
  const getUptimeData = (data) => {
    console.log(data, "dataaa");
    const sumOfTime = data?.uptimeReport?.reduce(
      (total, obj) => total + obj?.time,
      0
    );

    // Convert the sum to hours
    const sumInHours = sumOfTime;
    const hours = Math.floor(sumOfTime / 60);
    const minutes = sumOfTime % 60;

    const formattedSum = `${hours} hr ${minutes < 10 ? "0" : ""}${Math.floor(
      minutes
    )} min`;

    const average = sumInHours / data?.uptimeReport?.length;
    const aveHours = Math.floor(average / 60);
    const aveMinutes = average % 60;

    const aveFormattedSum = `${aveHours} hr ${
      aveMinutes < 10 ? "0" : ""
    }${Math.floor(aveMinutes)} min`;

    return [formattedSum, aveFormattedSum];
  };

  const columns = [
    {
      name: "Screen",
      selector: (row) => row?.name,
    },
    {
      name: "Total Uptime",
      selector: (row) => getUptimeData(row)[0],
    },
    {
      name: "Total Avg Time",
      selector: (row) => getUptimeData(row)[1],
    },
  ];
  const renderTotalUptime = (params) => {
    console.log(params.row.total_uptime, "111");
    return getUptimeData(params.row.total_uptime);
  };
  const renderTotalAvgUptime = (params) => {
    return getUptimeData(params.row.total_avg_uptime);
  };
  const columns1 = [
    {
      field: "screen",
      headerName: "Screen",
      flex: 1,
    },
    {
      field: "total_uptime",
      headerName: "Total Uptime",
      flex: 1,
      renderCell: renderTotalUptime,
    },
    {
      field: "total_avg_uptime",
      headerName: "Total Avg Time",
      flex: 1,
      renderCell: renderTotalAvgUptime,
    },
  ];

  return (
    <>
      <div className="h-100">
        <DataGrid
          getRowHeight={() => "auto"}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
          rows={rows1}
          columns={columns1}
          pageSize={10}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          //loading={loading}
          pagination
          zIndex={-1}
        />
      </div>
      {/* <Table
        responsive
        className="custom-table screen-table"
        style={{ height: "100%" }}
        id="external-events"
      >
        <thead>
          <tr>
            <th>Screen</th>
            <th>Total Uptime</th>
            <th>Daily Average Uptime</th>
          </tr>
        </thead>

        <tbody>
          {reportData.length > 0 &&
            reportData.map((data) => {
              
              return (
                <tr key={data?._id}>
                  <td>{data?.name}</td>
                  <td>{formattedSum} </td>
                  <td>{aveFormattedSum} </td>
                </tr>
              );
            })}
        </tbody>
        {reportData?.length === 0 && <h3 className="mt-5">No Report Found</h3>}
      </Table> */}
      {/* <Datatable columns={columns} data={reportData} pagination /> */}
    </>
  );
}
