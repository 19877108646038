import React, { useEffect, useState } from "react";
import "./tagInputField.css";
import { Badge } from "react-bootstrap";
import tagCloseIcon from "../../../img/tag-close-icon.png";
import { getSuggestionTag } from "../../../utils/api";
function TagInputField({ tags, setTags }) {
  const [inputValue, setInputValue] = useState("");
  const [tagSearch, setTagSearch] = useState("");
  const [tagList, setTagList] = useState("");
  console.log(inputValue);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setTagSearch(event.target.value)
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      if (tags.includes(inputValue.trim())) {
        setInputValue("");
        return;
      }
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleInputClick = () => {
    if (inputValue.trim()) {
      if (tags.includes(inputValue.trim())) {
        setInputValue("");
        return;
      }
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      setTagList("");
    }
  };

  const handleSearchTagClick = (tag) => {
    if (tag) {
      if (tags.includes(tag)) {
        setInputValue("");
        return;
      }
      setTags([...tags, tag]);
      setTagList("");
      setInputValue("");
    }
  };

  const handleRemoveTag = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const suggestionTag = async () => {
    const list = await getSuggestionTag(tagSearch);
    setTagList(list);
    console.log(list, "tagssss")
  };
  useEffect(() => {
    if(tagSearch.length > 2){
      suggestionTag();
    }
    if(tagSearch.length === 0){
      setTagList("")
    }
  
  }, [tagSearch])
  
  return (
    <div className="tag-input-field">
      {tags.length > 0 && (
        <div className="tag-name-row d-flex flex-wrap">
          {tags.map((tag) => (
            <Badge
              key={tag}
              className="tag-name mb-3"
              variant="outline-primary"
            >
              <span className="tag-name-content">{tag}</span>
              <span className="tag-close">
                <img
                  onClick={() => handleRemoveTag(tag)}
                  className="tag-close-icon"
                  src={tagCloseIcon}
                  alt="tag-icon"
                />
              </span>
            </Badge>
          ))}
        </div>
      )}

      <div
        className="form-group d-flex align-items-center"
        style={{
          border: "0.8px solid rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
        }}
      >
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          className="form-control input-default form-field border-0 "
          placeholder="Tags"
        />
        <button className="add-tag-btn" onClick={() => handleInputClick()}>
          {" "}
          Add
        </button>
      </div>

      {tagList.length > 0 && (
        <div className="tag-name-row d-flex flex-wrap">
          {tagList.map((tag, i) => (
            <Badge
              key={i}
              className="tag-name mb-3"
              variant="outline-primary"
              onClick={()=>handleSearchTagClick(tag)}
            >
              <span className="tag-name-content">{tag}</span>
          
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
}

export default TagInputField;
