import React, { useState } from "react";
import uploadImg from "../../../img/cloud-computing-icon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import deleteIcon from "../../../img/delete-icon.png";
import videopreview from "../../../img/video-preview.svg";

import ImageRotation from "../cropImage/ImageRotation";
function FileUploadWithPreview({
  setShowError,
  setFile,
  setFileMeta,
  setPreviewList,
  previewList,
  isLoading,
  // setShowAudio,
}) {
  const [preview, setPreview] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [selectedCroppedFile, setSelectedCroppedFile] = useState(null);
  const [selectedCroppedUrl, setSelectedCroppedUrl] = useState(null);
  const [checkCrop, setCheckCrop] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(event);
    if (!selectedFile) {
      //selectedFile(null);
      //setPreview(null);
      return;
    }
    setShowError(null);
    setFileList((fileList) => [...fileList, selectedFile]);
    setFile((file) => [...file, selectedFile]);
    // setSelectedCroppedUrl(URL.createObjectURL(selectedFile))
    // setSelectedCroppedFile(selectedFile);
    // setCheckCrop(true);

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = function (e) {
      const newP = {
        file: e.target.result,
        isLoading: false,
      };
      setPreviewList((previewList) => [...previewList, newP]);
    };
  };

  function handleLoadedMetadata(event) {
    console.log("event", event);
    const { naturalHeight, naturalWidth, duration } = event.target;

    const fMeta = {
      height: naturalHeight,
      width: naturalWidth,
      length: duration,
      fileLoading: false,
    };
    console.log(fMeta, "fMetafMeta");
    setFileMeta((fileMeta) => [...fileMeta, fMeta]);
  }

  const handleDelete = (e, item) => {
    e.preventDefault();
    const newPreview = previewList;
    const newFileList = fileList;

    delete newPreview[item];
    delete newFileList[item];

    // Set the new arrays
    setPreviewList(newPreview);
    setFile(newFileList);
    setShowError(null); // Clear the error state
  };

  return (
    <>
      <div className=" d-flex flex-column">
        {previewList &&
          previewList.length > 0 &&
          previewList.map((item, index) => {
            return (
              <div className="d-flex justify-content-between align-items-center mb-3">
                {item?.file?.includes("image") ? (
                  <img
                    onLoad={handleLoadedMetadata}
                    src={item.file}
                    alt="File Preview"
                    className="upload-file-in-asset"
                  />
                ) : item?.file?.includes("video") ? (
                  <>
                    <div className="d-flex align-items-center">
                      <video
                        poster={videopreview}
                        onLoadedData={handleLoadedMetadata}
                        // style={{ width: "50px", height: "50px", objectFit: "fill" }}
                        src={item.file}
                        className="upload-file-in-asset"
                      />

                      {/* <div className="d-flex ">
                        {" "}
                        <div className="form-check mr-4">
                          <h5 className=" m-0 text-black">Audio</h5>
                        </div>
                        <div className="form-check mr-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="audio"
                            value="true"
                            id="volume"
                            onChange={(e) => {
                              setShowAudio(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label mt-0"
                            htmlFor="volume"
                          >
                            Enable
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            placeholder="Preview Not Available"
                            className="form-check-input"
                            type="radio"
                            name="audio"
                            value="false"
                            id="valumeoff"
                            onChange={(e) => {
                              setShowAudio(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label mt-0"
                            htmlFor="valumeoff"
                          >
                            Disable
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <img
                  onClick={(e) => handleDelete(e, index)}
                  src={deleteIcon}
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
            );
          })}
        <div className="text-center">{isLoading && <CircularProgress />}</div>
      </div>
      {/* {previewList.length === 0 && ( */}
      <div
        className="upload-file-container relative d-flex align-items-center justify-content-center flex-column"
        style={{ position: "relative" }}
      >
        {previewList.length === 0 && (
          <div className=" d-flex align-items-center justify-content-center flex-column">
            <div className="upload-flie-img">
              <img className="upload-file" src={uploadImg} alt="upload-img" />
            </div>
            <h6>Click here to upload files</h6>
          </div>
        )}

        {previewList.length > 0 && (
          <button
            className={`btn btn-primary btn-block primary-btn add-file-media`}
          >
            + Add More
          </button>
        )}

        <input
          type="file"
          accept="image/*,video/*"
          className="upload-file-textfield"
          onChange={handleFileChange}
        />
      </div>
    </>
  );
}

export default FileUploadWithPreview;
