import React, { useState, useEffect } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import AddNewTagModal from "../../modals/AddNewTagModal";
import downArrow from "../../../img/down-arrow.svg";
import menuIcon from "../../../img/menu-icon.svg";
import veiwDetailIcon from "../../../img/view-detail-icon.png";
import defaultComparisonIcon from "../../../img/default-comparison-icon.png";
import assignIcon from "../../../img/assign-icon.png";
import listIcon from "../../../img/list-icon.png";
import takeScreenshotIcon from "../../../img/tack-screenshot-icon.png";
import { Link } from "react-router-dom";
import CompositionListModel from "../../modals/CompolistionListModel";
import { Button } from "react-bootstrap";
import FilterModal from "../../modals/FilterModal";
import QuickPlayModal from "../../modals/QuickPlayModal";

import {
  DataGrid,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import QuickSearchToolbar from "../QuickSearchToolbar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import {
  getDatetimeIn12Hours,
  humanReadableFormattedDateString,
} from "../../../utils/UtilsService";
import html2canvas from "html2canvas";
import IsOffline from "../../modals/IsOffline";
import { useParams, useHistory } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton  color='success' title="ABC" label={'ds'} startIcon={<Avatar />}  /> */}
      {/* <GridToolbarExport color='success' csvOptions={
        {
          fileName: 'customerList',
          utf8WithBom: true,
        }} /> */}
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const ListScreen = ({
  allScreens,
  userPermission,
  setIsRefresh,
  setFilterData,
  allFilterString,
  setIsOpenWebPlayer,
}) => {
  const history = useHistory();
  // console.log(setFilterData, "setFilterData");
  const [showNewTagModal, setNewTagModal] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState("");
  const [showPublishPopUp, setShowPublishPopUp] = useState(false);
  const [showFilterModal, setFilterModal] = useState(false);
  const [showQuickPlayModal, setQuickPlayModal] = useState(false);
  const [gridHeight, setGridHeight] = useState("100%"); // Initial height
  const type = ["shows", "tags", "groups"];
  const [notAssign, setNotAssign] = useState(false);
  const handleCaptureScreenshot = () => {
    // Capture the full screen using html2canvas
    html2canvas(document.body).then((canvas) => {
      // Convert the canvas to a data URL
      const screenshotDataUrl = canvas.toDataURL("image/png");

      // Create a link element to download the screenshot
      const downloadLink = document.createElement("a");
      downloadLink.href = screenshotDataUrl;
      downloadLink.download = "screenshot.png";

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up the link element
      document.body.removeChild(downloadLink);
    });
  };

  function checkIsoOffline(id, isOffline) {
    // console.log(isOffline, "isOffline");
  
    if (!isOffline) {
      setSelectedScreen(id);
      setNotAssign(true);
    } else {
      setSelectedScreen(id);
      setQuickPlayModal(true);
    }
  }

  const renderAction = (params) => {
    const { value } = params;
    // console.log(value, "valueeeee");
    return (
      // {!params.row.deactivate &&

      // }
      <div>
        <Dropdown
          className="dropdown-toggle-menu"
          disabled={params.row.deactivate}
          // style={{ position: "relative", top: "100%" }}
        >
          <Dropdown.Toggle variant="" className="p-0  mb-2">
            <span className="table-menu-icon">
              <img
                className="menu-img img-fluid"
                src={menuIcon}
                alt="menu-icon"
                style={{ height: "50px" }}
              />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href="#"
              className="dropdown-list-item"
              disabled={
                (userPermission && !userPermission.permission.SCREEN.view) ||
                params.row.deactivate
              }
            >
              <div
                // to={{
                //   pathname: `/display-detail `,
                // }}
                // state={{ id: value._id }}
                onClick={() =>
                  history.push({
                    pathname: "/display-detail",
                    state: { id: value._id, filter: allFilterString },
                  })
                }
              >
                <div className="d-flex">
                  <div className="dropdown-list-icon">
                    <img
                      className="dropdown-list-img img-fluid"
                      src={veiwDetailIcon}
                      alt="menu-icon"
                    />
                  </div>
                  <div className="dropdown-menu-list">
                    <span className="menu-heading">View Details</span>
                    <span className="menu-description">
                      Get to know more about screen info
                    </span>
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setShowPublishPopUp(true);
                setSelectedScreen(value._id);
              }}
              disabled={
                (userPermission && !userPermission.permission.SCREEN.edit) ||
                params.row.deactivate
              }
              className="dropdown-list-item"
            >
              <div className="d-flex">
                <div className="dropdown-list-icon">
                  <img
                    className="dropdown-list-img img-fluid"
                    src={defaultComparisonIcon}
                    alt="menu-icon"
                  />
                </div>
                <div className="dropdown-menu-list">
                  <span className="menu-heading">
                    Change Default Composition
                  </span>
                  <span className="menu-description">
                    Assign new composition as default
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                checkIsoOffline(value._id, value.isConnected);
              }}
              className="dropdown-list-item"
              disabled={
                (userPermission && !userPermission.permission.QUICKPLAY.add) ||
                params.row.deactivate
              }
            >
              <div className="d-flex">
                <div className="dropdown-list-icon">
                  <img
                    className="dropdown-list-img img-fluid"
                    src={assignIcon}
                    alt="menu-icon"
                  />
                </div>
                <div className="dropdown-menu-list">
                  <span className="menu-heading">Assign Quickplay</span>
                  <span className="menu-description">
                    Publish composition in this screen for next 10 minutes
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            {/* <Dropdown.Item
              href="#"
              className="dropdown-list-item"
              onClick={handleCaptureScreenshot}
            >
              <div className="d-flex">
                <div className="dropdown-list-icon">
                  <img
                    className="dropdown-list-img img-fluid"
                    src={takeScreenshotIcon}
                    alt="menu-icon"
                  />
                </div>
                <div className="dropdown-menu-list">
                  <span className="menu-heading">Take Screenshot</span>
                  <span className="menu-description">
                    For checking what's playing on screen
                  </span>
                </div>
              </div>
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderSchedule = (params) => {
    const { value } = params;
    let sch = "--";
    if (value && value[0]) {
      sch = value[0].name;
    }
    if (value && value.name) {
      sch = value.name;
    }
    return (
      <span className="td-content">
        <strong>{sch}</strong>
      </span>
    );
  };

  const renderDefault = (params) => {
    const { value } = params;
    let def = "--";
    if (value.defaultComposition) {
      def = value.defaultComposition.media.name;
    }
    return (
      <span className="td-content">
        <strong>{def}</strong>
      </span>
    );
  };

  const tagsRender = (params) => {
    const { value } = params;
    return (
      <div>
        <span className="tag-container">
          {params.row.tags.tags.length > 2 ? (
            <>
              <span className="my-phone-tag text-truncate ml-1 mr-1 mb-1">
                {params.row.tags.tags[0]}
              </span>
              <span className="my-phone-tag text-truncate ml-1 mr-1 mb-1">
                {params.row.tags.tags[1]}
              </span>
              <span>...</span>
            </>
          ) : (
            params.row.tags.tags.map((tag, index) => (
              <span
                key={index}
                className="my-phone-tag text-truncate ml-1 mr-1 mb-1"
              >
                {tag}
              </span>
            ))
          )}
          {/* <span className="my-phone-tag text-truncate ml-1 mr-1 mb-1">
            {params.row.tag}
          </span> */}
        </span>
        <span
          className="down-arrow"
          onClick={(e) => {
            handleTags(e, params.row.tags);
          }}
        >
          <img
            className="down-arrow-img img-fluid"
            src={downArrow}
            alt="arrow"
          />
        </span>
      </div>
    );
  };

  const groupRender = (params) => {
    const { value } = params;
    return Array.prototype.map.call(value, (s) => s.name).toString();
  };

  const lastSeenRender = (params) => {
    const { value } = params;
    return (
      <span className="d-flex align-items-center">
        <span
          className={`status ${
            value.isConnected ? "status-green" : "status-red"
          }`}
        ></span>
        <span className="td-content">
          <span>{value.isConnected ? "ONLINE" : "OFFLINE"}</span>
          {/* <strong>{humanReadableFormattedDateString(value)}</strong>{" "}
          <span>{getDatetimeIn12Hours(value)}</span> */}
        </span>
      </span>
    );
  };
  const rows1 = [];
  if (allScreens && allScreens.length > 0) {
    allScreens.forEach((item) => {
      rows1.push({
        id: item._id,
        // screen: {
        //   name: item.name,
        //   location: item.screenLocation,
        // },
        screen: item.name,
        locationa: item.screenLocation,
        deactivate: item.isDeleted,

        last_seen: item,
        schedule: item.schedule,
        tags: item,
        tag: item.tags[0],
        groups: item.groups,
        defaultComposition: item,
        default_composition: item.defaultComposition
          ? item.defaultComposition.media.name
          : " -- ",
        action: item,
      });
    });
  }

  const renderName = (params) => {
    const { value } = params;
    return (
      <span className="td-content">
        <strong>
          {value.name.length > 11
            ? //? value.name.slice(0, 11) + "..."
              value.name
            : value.name}
        </strong>
        <br />
        <span className="oooo">
          {value.location.length > 11
            ? //? value.location.slice(0, 11) + "..."
              value.location
            : value.location}
        </span>
      </span>
    );
  };

  const columns1 = [
    {
      field: "screen",
      headerName: "Screen",
      width: 200,
      // renderCell: renderName,
      sortable: true,
      renderCell: (params) => (
        <span>
          <strong>
            {params.row.screen.length > 11
              ? //? value.name.slice(0, 11) + "..."
                params.row?.screen
              : params.row?.screen}
          </strong>
          <br />
          <span>
            {params.row?.locationa.length > 11
              ? //? value.location.slice(0, 11) + "..."
                params.row?.locationa
              : params.row?.locationa}
          </span>
        </span>
      ),

      // filterOperators: ["contains", "equals"],
    },
    {
      field: "last_seen",
      headerName: "Last Seen",
      flex: 1,
      renderCell: lastSeenRender,

      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "default_composition",
      headerName: "Default Composition",
      flex: 1,
    },
    {
      field: "schedule",
      headerName: "Current Schedule",
      flex: 1,
      renderCell: renderSchedule,
      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "tags",
      headerName: "Tags",
      flex: 1,
      renderCell: tagsRender,
      sortable: true,
      // renderCell: (params) => <span>{params.row.tag}</span>,
      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    // {
    //   field: "groups",
    //   headerName: "Groups",
    //   flex: 1,
    //   renderCell: groupRender,
    //   disableExport: true,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: renderAction,
      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const handleTags = (e, item) => {
    e.preventDefault();
    setSelectedScreen(item);
    setNewTagModal(!showNewTagModal);
  };
  useEffect(() => {
    const updateGridHeight = () => {
      // Calculate the desired height based on your logic (e.g., content or viewport size)
      const newHeight = `${Math.min(
        document.documentElement.clientHeight - 100,
        1000
      )}px`;
      setGridHeight(newHeight);
    };

    // Update the height when the window is resized
    window.addEventListener("resize", updateGridHeight);

    // Initial height update
    updateGridHeight();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateGridHeight);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(timeZone, "aaaa")
  return (
    <>
      {notAssign && (
        <IsOffline
          setNotAssign={setNotAssign}
          assign={() => {
            setQuickPlayModal(true);
            setNotAssign(false);
          }}
        />
      )}

      <FilterModal
        showFilterModal={showFilterModal}
        setFilterModal={setFilterModal}
        setFilterData={setFilterData}
        setIsRefresh={setIsRefresh}
        type={type}
      />
      <QuickPlayModal
        showQuickPlayModal={showQuickPlayModal}
        setQuickPlayModal={setQuickPlayModal}
        //handleQuickPlay={handleQuickPlay}
        selected={selectedScreen}
        type={"composition"}
      />
      <div className="d-flex justify-content-end">
        <Button
          className="ml-2 icon-btn"
          variant="primary"
          onClick={() => {
            setFilterModal(true);
          }}
          style={{ position: "absolute", top: "10px" }}
        >
          <img className="icon-icon" src={listIcon} alt="list-icon" />
        </Button>
      </div>
      <div className="h-100" style={{ paddingBottom: "80px" }}>
        <DataGrid
          getRowHeight={() => "auto"}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
          rows={rows1}
          columns={columns1}
          pageSize={10}
          rowsPerPageOptions={[10]}
          //checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          //loading={loading}
          pagination
          zIndex={-1}
        />
      </div>
      {showNewTagModal && (
        <AddNewTagModal
          setNewTagModal={setNewTagModal}
          allScreens={allScreens}
          selected={selectedScreen}
          setIsRefresh={setIsRefresh}
          // type={"screen"}
        />
      )}
      {showPublishPopUp && (
        <CompositionListModel
          selected={selectedScreen}
          setShowPublishPopUp={setShowPublishPopUp}
          type="composition"
          setIsRefresh={setIsRefresh}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    userPermission: state.auth.permission,
  };
};
export default connect(mapStateToProps)(ListScreen);
