

import React, { useState, useEffect } from "react";
import Moment from "react-moment";

const TimeComponent = ({ format, initialDate }) => {
  const [currentTime, setCurrentTime] = useState(initialDate);
console.log(currentTime, "ggggg")
  useEffect(() => {
    const updateInterval = setInterval(() => {
      setCurrentTime(new Date()); // Increase time by 1 second
    }, 1000);

    return () => {
      clearInterval(updateInterval);
    };
  }, [currentTime]); // Re-run effect whenever currentTime changes

  return (
    <div>
      <Moment format={format} date={currentTime} />
    </div>
  );
};

export default TimeComponent;
