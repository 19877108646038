import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import editBtnImg from "../../../../img/edit-btn.png";
import deleteBtnImg from "../../../../img/delete-btn.png";
import { BASE_URL } from "../../../../utils/api";
import EditSelectedComposition from "../../../modals/editSelectedComposition";
import ZoneContentConfirmation from "../../../modals/ZoneContentConfirmation";

import weather from "../../../../img/weather.svg";
import urlapp from "../../../../img/urlapp.svg";

import youtube from "../../../../img/youtube.svg";
import scroller from "../../../../img/Scroller.svg";
import text from "../../../../img/text.svg";
import clock from "../../../../img/clock.svg";
import news from "../../../../img/news.svg";

import google from "../../../../img/Google.svg";
import Stocks from "../../../../img/Stocks.svg";
import qr from "../../../../img/qr.svg";
import aqi from "../../../../img/aqi.svg";
import rss from "../../../../img/rss.svg";
import people from "../../../../img/people.svg";

import quote from "../../../../img/quote 1.svg";
import bulletin from "../../../../img/Bulletin.svg";
import videopreview from "../../../../img/video-preview.svg";

const ZoneInfoTable = ({
  content,
  setContent,
  setReferenceUrl,
  layout,
  handleLayout,
  zoneError,
}) => {
  const [editSelected, setEditSelected] = useState(null);
  const [selectedZone, setSelectedZone] = useState("Zone1");

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [isZone3WeatherApp, setIsZone3WeatherApp] = useState(false);

  const [isZone3WeatherAppInContentList, setIsZone3WeatherAppInContentList] =
    useState(false);
  // console.log(foundObject, "foundObjectfoundObject");
  const handleChange = (event, index) => {
    const newValue = event.target.value.replace(/[^\d]/g, "");
    setContent((prev) => {
      const updateMedia = prev.map((val, key) => {
        if (key === index) {
          val.duration = newValue;
        }
        return val;
      });
      return [...updateMedia];
    });
  };
  const makeZoneColor = (zones) => {
    const data = {};
    zones.forEach((item, index) => {
      data[item.name] = index == 0 ? true : false;
    });
    return data;
  };
  const [zoneColor, setZoneColor] = useState(makeZoneColor(layout.zones));

  const handleZoneButton = (zone1) => {
    setZoneColor({ ...zoneColor, [selectedZone]: false, [zone1]: true });
    setSelectedZone(zone1);
    handleLayout(zone1);
  };

  const Duration = (composition, index) => {
    return (
      <div className="tag-container mediaDUrationTag">
        {" "}
        <input
          onChange={(event) => {
            handleChange(event, index);
          }}
          value={Number(composition.duration).toFixed(0)}
          disabled={composition.type === "video"}
        />
        <span>sec</span>
      </div>
    );
  };
  const TotalDuration = () => {
    let total = 0;
    content.forEach((composition) => {
      total += Number(composition.duration);
    });
    return total.toFixed(0);
  };

  const removeComposition = (index) => {
    setContent((prev) => {
      const updateMedia = prev.filter((val, key) => key !== index);
      return [...updateMedia];
    });

    setReferenceUrl((prev) => {
      const updateUrl = prev.filter((val, key) => key !== index);
      return [...updateUrl];
    });
  };

  const editComposition = (index) => {
    setEditSelected(index);
  };

  const updateViewType = (data, viewImage, ImgUrl) => {
    setContent((prev) => {
      const updateMedia = prev.map((val, key) => {
        if (key === editSelected) {
          val.fitToScreen = viewImage === "fitScreen";
          val.maintainAspectRatio = viewImage === "aspectRation";
          val.crop = viewImage === "crop" ? true : false;
        }
        return val;
      });
      return [...updateMedia];
    });

    setReferenceUrl((prev) => {
      const updateMedia = prev.map((val, key) => {
        if (key === editSelected) {
          return ImgUrl + "**" + selectedZone;
        } else {
          return val;
        }
      });
      return [...updateMedia];
    });
  };

  const handleButtonClick = (index) => {
    setSelectedButtonIndex(index);
    // Perform any other logic specific to the clicked button
  };

  // useEffect(() => {
  //   if (content) {
  //     let objecta = content.find(
  //       (item) => item.zone === "Zone3" && item.type === "weather-apps"
  //     );
  //     // if (isZone3WeatherApp) {
  //     //   objecta = null; // or objecta = {};
  //     // }

  //     // console.log(foundObject, "1111");
  //     setFoundObject(objecta);
  //     setIsZone3WeatherApp(!!objecta);
  //   }
  // }, [content]);
  useEffect(() => {
    if (content && layout.title === "Three Zone Landscape") {
      let filteredArray = content.filter(
        (item) =>
          item.zone === "Zone3" &&
          (item.type === "weather-apps" ||
            item.type === "news-apps" ||
            item.type === "google-apps" ||
            item.type === "bulletin-apps" ||
            item.type === "qrcode-apps" ||
            item.type === "people-apps")
      );
      if (filteredArray.length > 0) {
        if (isZone3WeatherAppInContentList) {
          filteredArray = [];
        } else {
          setIsZone3WeatherAppInContentList(true);
          setIsZone3WeatherApp(true);
        }
      }

      // console.log(content, "content");
      // setFoundObject(filteredArray);
      // setIsZone3WeatherAppInContentList(filteredArray.length > 0)
      // setIsZone3WeatherApp(filteredArray.length > 0);
    }

    if (content && layout.title === "Two Zone Landscape") {
      let filteredArray = content.filter(
        (item) =>
          item.zone === "Zone2" &&
          (item.type === "weather-apps" ||
            item.type === "news-apps" ||
            item.type === "google-apps" ||
            item.type === "bulletin-apps" ||
            item.type === "qrcode-apps" ||
            item.type === "people-apps")
      );
      if (filteredArray.length > 0) {
        if (isZone3WeatherAppInContentList) {
          filteredArray = [];
        } else {
          setIsZone3WeatherAppInContentList(true);
          setIsZone3WeatherApp(true);
        }
      }
    }
  }, [content]);

 

  return (
    <>
      {isZone3WeatherApp && (
        <ZoneContentConfirmation
          show={isZone3WeatherApp}
          assign={() => {
            setIsZone3WeatherApp(false);
          }}
          // filteredArray={filteredArray}
        />
      )}
      <Table
        responsive
        className="custom-table screen-table layout-table h-100"
      >
        <thead>
          <tr>
            <th colSpan={4}>
              <span className="d-flex flex-wrap">
                {layout &&
                (layout.title === "Single Zone Potrait" ||
                  layout.title === "Two Zone Potrait") ? (
                  <span
                    className={`yellow-box-portrait ${layout.title}`}
                    style={{
                      backgroundColor:
                        layout &&
                        (layout.zones.length == 2 ? "#fff" : "#ffc12b"),
                    }}
                  >
                    {layout && layout.zones.length == 2 ? (
                      <>
                        <div
                          className="zone-layout2"
                          style={{
                            backgroundColor: zoneColor["Zone1"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                        <div
                          className="zone-layout22"
                          style={{
                            backgroundColor: zoneColor["Zone2"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                ) : (
                  <span
                    className={`yellow-box ${layout.title}`}
                    style={{
                      backgroundColor:
                        layout &&
                        (layout.zones.length == 2 || layout.zones.length == 3
                          ? "#fff"
                          : "#ffc12b"),
                    }}
                  >
                    {layout && layout.zones.length == 2 ? (
                      <>
                        <div
                          className="zone-layout2"
                          style={{
                            backgroundColor: zoneColor["Zone1"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                        <div
                          className="zone-layout22"
                          style={{
                            backgroundColor: zoneColor["Zone2"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                      </>
                    ) : (
                      <></>
                    )}
                    {layout && layout.zones.length == 3 ? (
                      <>
                        <div
                          className="zone-layout31"
                          style={{
                            backgroundColor: zoneColor["Zone1"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                        <div
                          className="zone-layout32"
                          style={{
                            backgroundColor: zoneColor["Zone2"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                        <div
                          className="zone-layout33"
                          style={{
                            backgroundColor: zoneColor["Zone3"]
                              ? "#ffc12b"
                              : "",
                          }}
                        ></div>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                )}

                {layout &&
                  layout.zones.map((item, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          handleZoneButton(item.name);
                          handleButtonClick(i);
                          // setActiveClass(true);
                        }}
                        // className="zone"
                        // className={activeClass ? "zone zone-active " : "zone"}
                        className={
                          selectedButtonIndex === i
                            ? "zone zone-active "
                            : "zone"
                        }
                      >
                        {item.name}
                      </button>
                    );
                  })}
                <span className="duration">
                  Duration : {TotalDuration()} sec
                </span>
                {zoneError && <p className="mb-0 text-danger">{zoneError}</p>}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {content.map((composition, index) => {
          
            return composition.zone == selectedZone ? (
              <>
                {composition.type && composition.type === "youtube-apps" ? (
                  <tr key={index}>
                    <td colSpan={4} style={{ paddingBottom: 0 }}>
                      <span style={{ color: "red" }}>
                        <strong>Note</strong> : Please enter media duration...
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                <tr key={composition.id}>
                  <td>{index + 1}.</td>
                  <td>
                    <span className="td-content d-flex name-td-content">
                      <span
                        className={`name-img mr-2  `}
                      >
                        {composition.type === "image" && (
                          <img
                            className="media-img img-fluid"
                            src={`${BASE_URL}${composition.url}`}
                            alt="media-img"
                          />
                        )}
                        {/* {composition.type === "video" &&
                          composition.duration.toFixed(0) / 60} */}

                        {composition.type === "video" && (
                          // videoMetaDuration(media)

                          <img
                            src={videopreview}
                            style={{ width: "40px", objectFit: "cover" }}
                            alt="icon"
                          />
                        )}
                        {composition.type === "news-apps" && (
                          <img
                            src={news}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "quote-apps" && (
                          <img
                            src={quote}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "qrcode-apps" && (
                          <img
                            src={qr}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "clock-apps" && (
                          <img
                            src={clock}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "youtube-apps" && (
                          <img
                            src={youtube}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "url-apps" && (
                          <img
                            src={urlapp}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "scroller" && (
                          <img
                            src={scroller}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "text-apps" && (
                          <img
                            src={text}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "stocks-apps" && (
                          <img
                            src={Stocks}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "aqi-apps" && (
                          <img
                            src={aqi}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "weather-apps" && (
                          <img
                            src={weather}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "rss-apps" && (
                          <img
                            src={rss}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                        {composition.type === "people-apps" && (
                          <img
                            src={people}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "bulletin-apps" && (
                          <img
                            src={bulletin}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}

                        {composition.type === "google-apps" && (
                          <img
                            src={google}
                            className="media-img img-fluid"
                            alt="news-app"
                          />
                        )}
                      </span>
                      <span className="name-content d-flex flex-column flex-grow-1">
                        <strong>
                           {/* {composition.url.split("/")[
                            composition.url.split("/").length - 1
                          ].length > 22
                            ? composition.url
                                .split("/")
                                [composition.url.split("/").length - 1].slice(
                                  0,
                                  7
                                ) + "..."
                            : composition.url.split("/")[
                                composition.url.split("/").length - 1
                              ]}  */}

                              {composition.url.length >22 ?composition.url.slice(0,10)+ "...":composition.url}
                        </strong>
                        <span>{composition.createdBy}</span>
                      </span>
                    </span>
                  </td>
                  <td style={{ width: "180px" }}>
                    {Duration(composition, index)}
                  </td>
                  <td>
                    {/* {(composition.type === "video" ||
                      composition.type === "image") && (
                      <span className="layout-edit-btn mr-2 ">
                        <img
                          className="edit-icon cursorPointer"
                          src={editBtnImg}
                          alt="search"
                          onClick={() => {
                            // if(composition.type === "image"){
                            editComposition(index);
                            // }
                          }}
                        />
                      </span>
                    )} */}
                    <span
                      className="layout-edit-btn"
                      onClick={() => {
                        removeComposition(index);
                      }}
                    >
                      <img
                        className="edit-icon cursorPointer"
                        src={deleteBtnImg}
                        alt="search"
                      />
                    </span>
                  </td>
                </tr>
              </>
            ) : (
              <></>
            );
          })}
        </tbody>
      </Table>

      {editSelected !== null && (
        <EditSelectedComposition
          composition={content[editSelected]}
          setEditSelected={setEditSelected}
          updateViewType={updateViewType}
        />
      )}
    </>
  );
};

export default ZoneInfoTable;
