// import React from 'react'

// function WeatherUi() {
//   return (
//     <>
//       <div className="h-100 w-100 " style={{ color: "white" }}>
//       <div
//         className={`${prp.theme == "classic" ? "classic-bg" : ""} ${
//           prp.theme == "color" ? "classic-bg" : ""
//         } ${prp.theme == "grey" ? "weather-app-bg" : ""} w-100 h-100  ${
//           prp.isCorner ? "border-bg" : ""
//         }`}
//       >
//         {/* weather-app-bg border-bg */}
//         <div className="place-date-time d-flex align-items-center justify-content-between ">
//           <div className="place-date">
//             <h1>
//               {prp && prp.location && prp.location.address}

//               {/* {prp.location.address} */}
//             </h1>
//             <p>

//               <Moment
//                 format={"D MMM YYYY"}
//                 date={chicago_datetime_str}
//                 interval={10000}
//               />
//             </p>
//           </div>
//           <div className="time ">
//             <p>
            
//               <Moment
//                 format={"dddd"}
//                 date={chicago_datetime_str}
//                 interval={10000}
//               />
//             </p>
//             <p className="mb-0 ">
//               <Moment
//                 format={"hh:mm A"}
//                 date={chicago_datetime_str}
//                 interval={10000}
//               />

//               {/* <TimeComponent props={prp} /> */}
//             </p>
//           </div>
//         </div>
//         <div className="row temperature-box">
//           <div className="col-6 temperature text-white">
//             <div className="d-flex align-items-center">
//               <h1 className="text-white mb-0" style={{ fontSize: "75px" }}>
//                 {weatherInfo &&
//                   weatherInfo.list &&
//                   weatherInfo.list[1] &&
//                   (prp && prp.temp === "celsius"
//                     ? Math.round(
//                         (weatherInfo.list[1].main.temp - 273.15).toFixed(1)
//                       )
//                     : Math.round(
//                         (
//                           ((weatherInfo.list[1].main.temp - 273.15) * 9) / 5 +
//                           32
//                         ).toFixed(1)
//                       ))}
//               </h1>
//               {prp.temp === "celsius" ? (
//                 <span className="Celsius ml-2">
//                   <img src={Celsius} style={{ height: "80px" }} />
//                 </span>
//               ) : (
//                 <span className="Celsius ml-2">
//                   <img
//                     src={fahrenheit}
//                     style={{ height: "80px" }}
//                     alt="fahrenheit"
//                   />
//                 </span>
//               )}
//             </div>

//             <h2 className="text-white">
//               {weatherInfo &&
//                 weatherInfo.list &&
//                 weatherInfo.list[1] &&
//                 weatherInfo.list[1].weather[0].description}
//             </h2>
//           </div>
//           <div className="col-6">
//             <div className="row other-day-weather">
//               {prp &&
//                 prp.isForcast &&
//                 weatherInfo &&
//                 weatherInfo.list &&
//                 weatherInfo.list.map((item, index) => {
//                   return (
//                     (index == 15 ||
//                       index == 22 ||
//                       index == 29 ||
//                       index == 36) && (
//                       <div className="col-6">
//                         <p className="day">
//                           <Moment
//                             format={"D MMM YYYY"}
//                             date={new Date(item.dt_txt)}
//                           />
//                         </p>
//                         <div className="d-flex align-items-center">
//                           <h2>
//                             {prp.temp === "celsius"
//                               ? Math.round((item.main.temp - 273.15).toFixed(1))
//                               : Math.round(
//                                   (
//                                     ((item.main.temp - 273.15) * 9) / 5 +
//                                     32
//                                   ).toFixed(1)
//                                 )}
//                           </h2>
//                           {prp.temp === "celsius" ? (
//                             <span className="Celsius ml-2">
//                               <img src={Celsius} style={{ height: "30px" }} />
//                             </span>
//                           ) : (
//                             <span className="Celsius ml-2">
//                               <img
//                                 src={fahrenheit}
//                                 style={{ height: "30px" }}
//                                 alt="fahrenheit"
//                               />
//                             </span>
//                           )}
//                         </div>

//                         <p>{item.weather[0].description}</p>
//                       </div>
//                     )
//                   );
//                 })}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   )
// }

// export default WeatherUi

// import React, { useEffect, useState } from "react";
// import Moment from "react-moment";
// import Celsius from "../../img/thermometer 1.svg";
// import Fahrenheit from "../../img/fahrenheit-degrees-1.svg";
// import { getWeather } from "../../utils/api";
// const WeatherUi = ({ prp, weatherInfoa }) => {
//     const [weatherInfo, setWeatherInfo] = useState(null);

//   let timeZ =
//     prp.location.timeZone && prp.location.timeZone
//       ? prp.location.timeZone.timeZoneId
//       : "Asia/Riyadh";
//   let chicago_datetime_str = new Date().toLocaleString("en-US", {
//     timeZone: timeZ,
//   });
 
//   const getWeatherDetail = async (lat, long) => {
//     const locationData = await getWeather(lat, long);
//     setWeatherInfo(locationData);
   
//   };

//   useEffect(() => {
//     const intervalId = async () => {
  

//       // Check if location is defined and has latitude and longitude
//       if ( prp && prp.location.latitude && prp.location.longitude) {
 
//         try {
//           const weatherData = await getWeatherDetail(
//             prp.location.latitude,
//             prp.location.longitude
//           );

//           if (weatherData && weatherData.cod !== undefined) {
//             setWeatherInfo((prevData) => ({ ...prevData, ...weatherData }));
         
//           } else {
//             console.log("Invalid weather data received:", weatherData);
//           }
//         } catch (error) {
//           console.error("Error fetching weather information", error);
//         }
//       }
//     };
//     intervalId();
//   }, [prp.location.latitude]); // Include only location in the dependency array

//   return (
//     <div className="h-100 w-100 " style={{ color: "white" }}>
//       <div
//         className={`${prp.theme === "classic" ? "classic-bg" : ""} ${
//           prp.theme === "color" ? "classic-bg" : ""
//         } ${prp.theme === "grey" ? "weather-app-bg" : ""} w-100 h-100 ${
//           prp.isCorner ? "border-bg" : ""
//         }`}
//       >
//         <div className="place-date-time d-flex align-items-center justify-content-between">
//           <div className="place-date">
//             <h1>{prp && prp.location && prp.location.address}</h1>
//             <p>
//               <Moment format={"D MMM YYYY"} date={chicago_datetime_str} interval={10000} />
//             </p>
//           </div>
//           <div className="time">
//             <p>
//               <Moment format={"dddd"} date={chicago_datetime_str} interval={10000} />
//             </p>
//             <p className="mb-0">
//               <Moment format={"hh:mm A"} date={chicago_datetime_str} interval={10000} />
//             </p>
//           </div>
//         </div>
//         <div className="row temperature-box">
//           <div className="col-6 temperature text-white">
//             <div className="d-flex align-items-center">
//               <h1 className="text-white mb-0" style={{ fontSize: "75px" }}>
//                 {weatherInfo && weatherInfo.list && weatherInfo.list[1] && (
//                   prp && prp.temp === "celsius"
//                     ? Math.round((weatherInfo.list[1].main.temp - 273.15).toFixed(1))
//                     : Math.round(((weatherInfo.list[1].main.temp - 273.15) * 9) / 5 + 32)
//                 )}
//               </h1>
//               {prp.temp === "celsius" ? (
//                 <span className="Celsius ml-2">
//                   <img src={Celsius} style={{ height: "80px" }} alt="celsius" />
//                 </span>
//               ) : (
//                 <span className="Celsius ml-2">
//                   <img src={Fahrenheit} style={{ height: "80px" }} alt="fahrenheit" />
//                 </span>
//               )}
//             </div>
//             <h2 className="text-white">
//               {weatherInfo && weatherInfo.list && weatherInfo.list[1] && weatherInfo.list[1].weather[0].description}
//             </h2>
//           </div>
//           <div className="col-6">
//             <div className="row other-day-weather">
//               {prp &&
//                 prp.isForcast &&
//                 weatherInfo &&
//                 weatherInfo.list &&
//                 weatherInfo.list.map((item, index) => (
//                   (index === 15 || index === 22 || index === 29 || index === 36) && (
//                     <div className="col-6" key={index}>
//                       <p className="day">
//                         <Moment format={"D MMM YYYY"} date={new Date(item.dt_txt)} />
//                       </p>
//                       <div className="d-flex align-items-center">
//                         <h2>
//                           {prp.temp === "celsius"
//                             ? Math.round((item.main.temp - 273.15).toFixed(1))
//                             : Math.round((((item.main.temp - 273.15) * 9) / 5 + 32).toFixed(1))}
//                         </h2>
//                         {prp.temp === "celsius" ? (
//                           <span className="Celsius ml-2">
//                             <img src={Celsius} style={{ height: "30px" }} alt="celsius" />
//                           </span>
//                         ) : (
//                           <span className="Celsius ml-2">
//                             <img src={Fahrenheit} style={{ height: "30px" }} alt="fahrenheit" />
//                           </span>
//                         )}
//                       </div>
//                       <p>{item.weather[0].description}</p>
//                     </div>
//                   )
//                 ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WeatherUi;



import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Celsius from "../../img/thermometer 1.svg";
import Fahrenheit from "../../img/fahrenheit-degrees-1.svg";
import { getWeather } from "../../utils/api";

const WeatherUi = ({ prp }) => {
  const [weatherInfo, setWeatherInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  let timeZ =
    prp.location.timeZone && prp.location.timeZone.timeZoneId
      ? prp.location.timeZone.timeZoneId
      : "Asia/Riyadh";

  let chicago_datetime_str = new Date().toLocaleString("en-US", {
    timeZone: timeZ,
  });

  const getWeatherDetail = async (lat, long) => {
    setLoading(true); // Set loading to true when API call starts
    try {
      const locationData = await getWeather(lat, long);
      setWeatherInfo(locationData);
    } finally {
      setLoading(false); // Set loading to false when API call completes
    }
  };

  useEffect(() => {
    const fetchWeather = async () => {
      if (prp && prp.location.latitude && prp.location.longitude) {
        try {
          const weatherData = await getWeatherDetail(
            prp.location.latitude,
            prp.location.longitude
          );
          if (weatherData && weatherData.cod !== undefined) {
            setWeatherInfo(weatherData);
          } else {
            console.log("Invalid weather data received:", weatherData);
          }
        } catch (error) {
          console.error("Error fetching weather information", error);
        }
      }
    };

    fetchWeather();
  }, [prp.location.latitude, prp.location.longitude]);

  return (
    <div className="h-100 w-100" style={{ color: "white" }}>
      <div
        className={`${prp.theme === "classic" ? "classic-bg" : ""} ${
          prp.theme === "color" ? "classic-bg" : ""
        } ${prp.theme === "grey" ? "weather-app-bg" : ""} w-100 h-100 ${
          prp.isCorner ? "border-bg" : ""
        }`}
      >
        <div className="place-date-time d-flex align-items-center justify-content-between">
          <div className="place-date">
            <h1>{prp && prp.location && prp.location.address}</h1>
            <p>
              <Moment format={"D MMM YYYY"} date={chicago_datetime_str} interval={10000} />
            </p>
          </div>
          <div className="time">
            <p>
              <Moment format={"dddd"} date={chicago_datetime_str} interval={10000} />
            </p>
            <p className="mb-0">
              <Moment format={"hh:mm A"} date={chicago_datetime_str} interval={10000} />
            </p>
          </div>
        </div>
        <div className="row temperature-box">
          <div className="col-6 temperature text-white">
            <div className="d-flex align-items-center">
              {loading ? ( // Show loading indicator while loading
                <h1 className="text-white mb-0" style={{ fontSize: "75px" }}>
                  Loading...
                </h1>
              ) : (
                <>
                  <h1 className="text-white mb-0" style={{ fontSize: "75px" }}>
                    {weatherInfo && weatherInfo.list && weatherInfo.list[1] && (
                      prp && prp.temp === "celsius"
                        ? Math.round((weatherInfo.list[1].main.temp - 273.15).toFixed(1))
                        : Math.round(((weatherInfo.list[1].main.temp - 273.15) * 9) / 5 + 32)
                    )}
                  </h1>
                  {prp.temp === "celsius" ? (
                    <span className="Celsius ml-2">
                      <img src={Celsius} style={{ height: "80px" }} alt="celsius" />
                    </span>
                  ) : (
                    <span className="Celsius ml-2">
                      <img src={Fahrenheit} style={{ height: "80px" }} alt="fahrenheit" />
                    </span>
                  )}
                </>
              )}
            </div>
            <h2 className="text-white">
              {loading ? (
                "Loading description..."
              ) : (
                weatherInfo && weatherInfo.list && weatherInfo.list[1] && weatherInfo.list[1].weather[0].description
              )}
            </h2>
          </div>
          <div className="col-6">
            <div className="row other-day-weather">
              {prp &&
                prp.isForcast &&
                weatherInfo &&
                weatherInfo.list &&
                weatherInfo.list.map((item, index) => (
                  (index === 15 || index === 22 || index === 29 || index === 36) && (
                    <div className="col-6" key={index}>
                      <p className="day">
                        <Moment format={"D MMM YYYY"} date={new Date(item.dt_txt)} />
                      </p>
                      <div className="d-flex align-items-center">
                        <h2>
                          {prp.temp === "celsius"
                            ? Math.round((item.main.temp - 273.15).toFixed(1))
                            : Math.round((((item.main.temp - 273.15) * 9) / 5 + 32).toFixed(1))}
                        </h2>
                        {prp.temp === "celsius" ? (
                          <span className="Celsius ml-2">
                            <img src={Celsius} style={{ height: "30px" }} alt="celsius" />
                          </span>
                        ) : (
                          <span className="Celsius ml-2">
                            <img src={Fahrenheit} style={{ height: "30px" }} alt="fahrenheit" />
                          </span>
                        )}
                      </div>
                      <p>{item.weather[0].description}</p>
                    </div>
                  )
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherUi;
