import { Button, Modal, Row, Col } from "react-bootstrap";
import cancelIcon from "../../img/cancel-icon.png";
import { changePassword } from "../../utils/api";
import { toast } from "react-toastify";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const ChangePassword = ({ setShowModel, show }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // const isStrongPassword = (password) => {
  //   // Password strength criteria
  //   const hasNumber = /\d/.test(password);
  //   const hasUpperCase = /[A-Z]/.test(password);
  //   const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  //   // Return true if all criteria are met
  //   return hasNumber && hasUpperCase && hasSpecialChar;
  // };

  const isStrongPassword = (password) => {
    // Password strength criteria
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMinLength = password.length >= 8;
    const hasLowerCase = (password.match(/[a-z]/g) || []).length >= 3; // Count lowercase letters
  
    // Return true if all criteria are met
    return hasUpperCase && hasSpecialChar && hasMinLength && hasLowerCase;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStrongPassword(newPassword)) {
      setPasswordError(
        // "Password must include numbers, uppercase, and special characters."
        "Password Must have at least 8 characters. Must have at least 1 Upper case letter. Must have at least 3 Lower case letters. Must have at least 1 special character"
      );
      return;
    }
    try {
      const response = await changePassword(oldPassword, newPassword);
      console.log(response.data.message, "successssss");
      notifyTopRight(response.data.message);
      setOldPassword("");
      setNewPassword("");
      setPasswordError(null);
      setShowModel(false);
    } catch (error) {
      console.log(error.response.data, "error");
      notifyError(error.response.data.message);
    }
  };
  function handleClose() {
    setShowModel(false);
    setOldPassword("");
    setNewPassword("");
  }
  return (
    <Modal
      className="fade bd-example-modal-lg mt-4 custom-modal custom-modal-medium"
      show={show}
      size="md"
    >
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
        <Button variant="" className="close" onClick={() => handleClose()}>
          <img className="cancel-icon" src={cancelIcon} alt="cancel-icon" />
        </Button>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mb-3 ">
            <strong>
              <label className="mb-3"> Old Password</label>
            </strong>

            <div className="form-group password-textfield">
              <input
                type={isVisible ? "text" : "password"}
                className="form-control"
                autocomplete="off"
                name="name"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter your old password"
                required
                minLength={6}
              />
              <span
                className="eye-off"
                onClick={() => {
                  setIsVisible(!isVisible);
                }}
              >
                {isVisible ? (
                  <VisibilityIcon className="eye-off" />
                ) : (
                  <VisibilityOffIcon className="eye-off" />
                )}{" "}
              </span>
            </div>
          </div>
          <div className="mb-4 ">
            <strong>
              <label className="mb-3"> New Password</label>
            </strong>
            <div className="form-group password-textfield">
              <input
                type={isVisiblePassword ? "text" : "password"}
                className="form-control"
                autocomplete="off"
                name="department"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                minLength={6}
                required
              />
              <span
                className="eye-off"
                onClick={() => {
                  setIsVisiblePassword(!isVisiblePassword);
                }}
              >
                {isVisiblePassword ? (
                  <VisibilityIcon className="eye-off" />
                ) : (
                  <VisibilityOffIcon className="eye-off" />
                )}
              </span>
            </div>
            {passwordError && (
              <div className="text-danger">{passwordError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100 m-0">
            <Col lg={6} md={6} sm={6} xs={6} className="pl-0 pr-2">
              <Button
                className="cancel-btn w-100"
                variant="outline-light"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="pl-2 pr-0">
              <Button
                variant=""
                type="submit"
                className="btn btn-primary btn-block primary-btn"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangePassword;
