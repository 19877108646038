import React, { useState } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import { humanReadableFormattedDateString } from "../../../utils/UtilsService";
import Datatable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import QuickSearchToolbar from "../QuickSearchToolbar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton  color='success' title="ABC" label={'ds'} startIcon={<Avatar />}  /> */}
      {/* <GridToolbarExport color='success' csvOptions={
          {
            fileName: 'customerList',
            utf8WithBom: true,
          }} /> */}
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}
export default function Audit({ reportData, cPage, pCount }) {
  const rows1 = [];
  if (reportData && reportData.length > 0) {
    reportData.forEach((item) => {
      rows1.push({
        id: item._id,
        title: item.title,

        vendor: item.vendor?.name,
        created_at: item.createdAt,
      });
    });
  }
  // const columns = [
  //     {
  //         name : "Title",
  //         selector : (row) => row?.title,
  //         sortable: true,
  //     },
  //     {
  //         name : "Vendor",
  //         selector : (row) => row?.vendor?.name,
  //         sortable: true,
  //     },
  //     {
  //         name : "Created At",
  //         selector : (row) => humanReadableFormattedDateString(row.createdAt),
  //         sortable: true,
  //     },
  // ];
  const render = (params) => {
    return humanReadableFormattedDateString(params.row.created_at);
  };
  const columns1 = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: render,
    },
  ];
  return (
    <>
      <div className="h-100">
        <DataGrid
          getRowHeight={() => "auto"}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
          rows={rows1}
          columns={columns1}
          pageSize={10}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          //loading={loading}
          pagination
          zIndex={-1}
        />
      </div>
      {/* <Datatable columns={columns} data={reportData} pagination sorting /> */}
    </>
  );
}
