import { Button, Modal } from "react-bootstrap";

const ZoneContentConfirmation = ({ show, assign }) => {
  return (
    <Modal className="fade" show={show}>
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
        <Button variant="" className="close" onClick={() => assign()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body className="text-black pb-0">
        <div className="d-flex">
          <div>Note:</div>

          <div className="ml-2">
            Only this content played in footer zone .
            <br />
            <p className="mt-3">
              {/* (All News , Weather , QR code , People space , Google slide) */}
              (Image, Video, Youtube, Scroller, URL, Digital Clock, Quote)



            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex">
          {/* <Button
            className="cancel-btn  small-btn mr-3 small-btn-close"
            variant="outline-light"
            onClick={() => assign()}
          >
            Cancel
          </Button> */}

          <Button
            variant=""
            type="button"
            className="btn btn-primary btn-block primary-btn small-btn"
            onClick={() => assign()}
          >
            Continue Anyway
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ZoneContentConfirmation;
