// import axios from 'axios'

// const fetchClient = () => {
//   const defaultOptions = {
//     headers: {
//       Authorization: '',
//       'Content-Type': 'application/json'
//     }
//   }

//   // Create instance
//   const instance = axios.create(defaultOptions)

//   // Set the AUTH token for any request
//   instance.interceptors.request.use(function (config) {
//     const tokenDetailsString = localStorage.getItem('userDetails');
//     const tokenDetails = JSON.parse(tokenDetailsString);
//     const token = tokenDetails?.token?.token;
//     const ISSERVER = typeof window === 'undefined'
//     if (!ISSERVER) {
//       if (config && config.headers) {
//         config.headers.Authorization = token ? `Bearer ${token}` : ''
//       }
//     }
//     return config
//   })

//   return instance
// }

// export default fetchClient()



import axios from 'axios';
import { toast } from "react-toastify";
const fetchClient = () => {
  const defaultOptions = {
    headers: {
      Authorization: '',
      'Content-Type': 'application/json'
    }
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails?.token?.token;
    const ISSERVER = typeof window === 'undefined';
    if (!ISSERVER) {
      if (config && config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
            // Get the local time zone
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Add the local time zone to the headers
            config.headers['X-Time-Zone'] = timeZone;
      }
    }
    return config;
  });

  // Add a response interceptor to handle errors
  instance.interceptors.response.use(
    function (response) {
      // Return a successful response
      return response;
    },
    function (error) {
      // console.log(error.response, "errorerror")
      // Handle errorif

      if (error.response && error.response.status === 400){
        notifyError(error?.response?.data?.message);
      }
      if (error.response && error.response.status === 401) {
        // Unauthorized error
        // Remove token from localStorage
        localStorage.removeItem('userDetails');
        // Redirect to the login page
        // window.location.href = '/login'; // Adjust the path as needed
        window.location.replace("/login");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default fetchClient();
