import React, { useEffect, useRef } from "react";
import videojs from "video.js";

import "video.js/dist/video-js.css";
const WebVideoPlayer = (props) => {
  console.log(props, "WebVideoPlayer.jsx props");
  const rotateMode = "potrait";
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // useEffect(() => {
  //   videoRef.current?.load();
  //   // playerRef.current = videojs(videoRef.current, {
  //   //   autoplay: true,
  //   //   controls: false,
  //   //   muted:true,
  //   //   loop:true,
  //   //   src: props.src
  //   // });
  //   // console.log("test",playerRef.current, videoRef.current)
  //   return () => {
  //     videoRef.current?.load();
  //     if (playerRef.current) {
  //       playerRef.current.dispose();
  //       playerRef.current = null;
  //     }
  //   };
  // }, [props.src]);

  // useEffect(() => {
  //   // Function to unmute the video and trigger a click event
  //   const unmuteVideo = () => {
  //     if (videoRef.current) {
  //       videoRef.current.muted = false;
  //       videoRef.current.play();
  //       videoRef.current.click(); // Simulate a click event to unmute
  //     }
  //   };

  //   // Call the function after the video has loaded
  //   videoRef.current.addEventListener("loadedmetadata", unmuteVideo);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     if (videoRef.current) {
  //       videoRef.current.removeEventListener("loadedmetadata", unmuteVideo);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (playerRef.current) {
  //     playerRef.current.src({ src: props.src });
  //   }
  // }, [props.src]);

  return (
    <>
      <video
        // ref={videoRef}
        className="video-js"
        id={`${
          props.layout && props.layout === rotateMode
            ? "video-player-portrait"
            : ""
        }`}
        // id="video-player-portrait"
        autoPlay
        muted
        controls
        loop
      >
        <source src={props.src} type="video/mp4" />
      </video>
    </>
  );
};

export default WebVideoPlayer;
