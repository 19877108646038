import { Button, Modal } from "react-bootstrap";

const IsOffline = ({ setNotAssign, assign }) => {
  return (
    <Modal className="fade" show={true}>
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => setNotAssign(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body className="text-black">
        Warning: The screen you have selected is offline Would you like to
        continue?
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex">
          <Button
            className="cancel-btn  small-btn mr-3 small-btn-close"
            variant="outline-light"
            onClick={() => setNotAssign(false)}
          >
            Cancel
          </Button>

          <Button
            variant=""
            type="button"
            className="btn btn-primary btn-block primary-btn small-btn"
            onClick={() => assign()}
          >
            Continue Anyway
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default IsOffline;
