import React, { useEffect } from "react";
import { connect } from "react-redux";
import weather from "../../../img/weather.svg";
import urlapp from "../../../img/urlapp.svg";

import menuIcon from "../../../img/menu-icon.svg";
import UrlAppModal from "../../modals/UrlAppModal";
import youtube from "../../../img/youtube.svg";
import scroller from "../../../img/Scroller.svg";
import text from "../../../img/text.svg";
import clock from "../../../img/clock.svg";
import news from "../../../img/news.svg";

import google from "../../../img/Google.svg";
import Stocks from "../../../img/Stocks.svg";
import qr from "../../../img/qr.svg";
import aqi from "../../../img/aqi.svg";
import rss from "../../../img/rss.svg";
import people from "../../../img/people.svg";

import quote from "../../../img/quote 1.svg";
import bulletin from "../../../img/Bulletin.svg";
import { useState } from "react";
import RssFeedAppModal from "../../modals/RssFeedAppModal";
import ScrollerTextAppModal from "../../modals/ScrollerTextAppModal";
import WeatherAppModal from "../../modals/WeatherAppModal";
import TextAppModal from "../../modals/TextAppModal";
import ClockApp from "../../modals/ClockApp";
import StocksAppModal from "../../modals/StocksAppModal";
import QrCodeModal from "../../modals/QrCodeModal";
import AirQualityAppModal from "../../modals/AirQualityAppModal";
import YoutubeAppModal from "../../modals/YoutubeAppModal";
import BulletinBoardAppModal from "../../modals/BulletinBoardAppModal";
import GoogleSlideAppModal from "../../modals/GoogleSlideAppModal";
import QuoteModel from "../../modals/QuoteModel.jsx";
import AllNewsAppModal from "../../modals/AllNewsAppModal";

import { useHistory } from "react-router-dom";


const Integrations = ({ permission, auth }) => {
  const history = useHistory();
  const [showUrlApp, setShowUrlApp] = useState(false);
  const [showRssFeedApp, setShowRssFeedApp] = useState(false);
  const [showScrollerTextApp, setShowScrollerTextApp] = useState(false);
  const [showWeatherApp, setShowWeatherApp] = useState(false);
  const [showTextApp, setShowTextApp] = useState(false);
  const [showClockApp, setShowClockApp] = useState(false);
  const [showStocksApp, setShowStocksApp] = useState(false);
  const [showQrCodeApp, setShowQrCodeApp] = useState(false);
  const [showAirQualityApp, setShowAirQualityApp] = useState(false);
  const [showYoutubeApp, setShowYoutubeApp] = useState(false);
  const [showBulletinBoardApp, setShowBulletinBoardApp] = useState(false);
  const [showGoogleSlideApp, setShowGoogleSlideApp] = useState(false);
  const [showQuotesApp, setShowQuotesApp] = useState(false);
  const [showNewsApp, setShowNewsApp] = useState(false);

  const appList = [
    { title: "URL APP", slug: "url-app", icon: urlapp },
    { title: "YOUTUBE", slug: "youtube", icon: youtube },

    { title: "SCROLLER", slug: "scroller", icon: scroller },
    { title: "WEATHER", slug: "weather", icon: weather },
    { title: "TEXT", slug: "text", icon: text },
    { title: "CLOCK APP", slug: "clock-app", icon: clock },

    // { title: " BULLETIN APP", slug: "bulletin-app", icon: bulletin },
    { title: "GOOGLE SLIDES", slug: "google-slides", icon: google },
    { title: "All NEWS APP", slug: "all-news-app", icon: news },
    { title: "QUOTES", slug: "quotes", icon: quote },

    // { title: "STOCKS", slug: "stocks", icon: Stocks },
    { title: "QR CODE", slug: "qr-code", icon: qr },

    // { title: "AIR QUALITY APP", slug: "air-quality-app", icon: aqi },
    { title: "RSS FEED", slug: "rss-feed", icon: rss },
    { title: "PEOPLE SPACE", slug: "people-space", icon: people },
  ];

  const handleChange = (e, type) => {
    e.preventDefault();
    // console.log(type);
    if (permission && permission.permission.APPS.add) {
      if (type && type == "url-app") {
        setShowUrlApp(true);
      }
      if (type && type == "rss-feed") {
        setShowRssFeedApp(true);
      }
      if (type && type == "youtube") {
        setShowYoutubeApp(true);
      }
      if (type && type == "scroller") {
        setShowScrollerTextApp(true);
      }
      if (type && type == "text") {
        setShowTextApp(true);
      }
      if (type && type == "clock-app") {
        setShowClockApp(true);
      }
      if (type && type == "weather") {
        setShowWeatherApp(true);
      }
      if (type && type == "qr-code") {
        setShowQrCodeApp(true);
      }
      if (type && type == "air-quality-app") {
        setShowAirQualityApp(true);
      }
      if (type && type == "stocks") {
        setShowStocksApp(true);
      }
      if (type && type == "all-news-app") {
        setShowNewsApp(true);
      }
      if (type && type == "bulletin-app") {
        setShowBulletinBoardApp(true);
      }
     
      if (type && type == "google-slides") {
        setShowGoogleSlideApp(true);
      }
      if (type && type == "quotes") {
        setShowQuotesApp(true);
      }
      if (type && type == "people-space") {
        history.push("/people-space");
      }
    }
  };
  return (
    <>
      <div className="custom-content-heading d-flex flex-wrap flex-column">
        <h1 className="mb-3">Integrations</h1>
      </div>
      <div
        className="integration-container h-100"
        style={{ overflowY: "auto" }}
      >
        {appList.map((item) => {
          return (
            <div className="app-card ">
              <div
                className="d-flex align-items-center justify-content-center h-100"
                onClick={(e) => {
                  handleChange(e, item.slug);
                }}
              >
                <div className="text-center">
                  <img className="mb-3 app-icon" src={item.icon} />
                  <p>{item.title}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <UrlAppModal
        setShowUrlApp={() => setShowUrlApp(false)}
        show={showUrlApp}
      />
      <RssFeedAppModal
        setShowUrlApp={() => setShowRssFeedApp(false)}
        show={showRssFeedApp}
      />
      <ScrollerTextAppModal
        show={showScrollerTextApp}
        setShowScrollerTextApp={setShowScrollerTextApp}
      />
      <WeatherAppModal
        setShowUrlApp={() => setShowWeatherApp(false)}
        show={showWeatherApp}
      />
      <TextAppModal
        setShowUrlApp={() => setShowTextApp(false)}
        show={showTextApp}
      />
      <ClockApp
        setShowUrlApp={() => setShowClockApp(false)}
        show={showClockApp}
      />
      <StocksAppModal
        setShowUrlApp={() => setShowStocksApp(false)}
        show={showStocksApp}
      />
      <QrCodeModal
        setShowUrlApp={() => setShowQrCodeApp(false)}
        show={showQrCodeApp}
      />
      <AirQualityAppModal
        setShowUrlApp={() => setShowAirQualityApp(false)}
        show={showAirQualityApp}
      />
    
        <YoutubeAppModal
          setShowUrlApp={() => setShowYoutubeApp(false)}
          show={showYoutubeApp}
        />
    
      <BulletinBoardAppModal
        setShowUrlApp={() => setShowBulletinBoardApp(false)}
        show={showBulletinBoardApp}
      />
      <GoogleSlideAppModal
        setShowUrlApp={() => setShowGoogleSlideApp(false)}
        show={showGoogleSlideApp}
      />
      <QuoteModel
        setShowUrlApp={() => setShowQuotesApp(false)}
        show={showQuotesApp}
      />
   
        <AllNewsAppModal
          setShowUrlApp={() => setShowNewsApp(false)}
          show={showNewsApp}
        />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // errorMessage: state.auth.errorMessage,
    // successMessage: state.auth.successMessage,
    auth: state.auth.auth,
    permission: state.auth.permission,
  };
};
export default connect(mapStateToProps)(Integrations);
